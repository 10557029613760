.grid-child-form {
  background: linear-gradient(260deg, rgb(242, 120, 128) 0%, rgb(255, 249, 243) 65%);
}

.login-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.login-form h2 {
  margin-bottom: 1rem;
}
.login-form > *:last-child {
  margin: auto auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.login-form .error-msg {
  color: rgb(242, 120, 128);
}

.input-label-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0.5rem 0;
}
.input-label-container label {
  margin: 0.25rem 0;
}
.input-label-container input, .input-label-container textarea, .input-label-container select {
  background-color: transparent;
  width: 100%;
  color: black;
  border: none;
  border-bottom: 1px solid rgb(112, 112, 113);
}
.input-label-container input::-moz-placeholder, .input-label-container textarea::-moz-placeholder, .input-label-container select::-moz-placeholder {
  font-weight: 200;
  font-size: 0.8rem;
  color: rgb(112, 112, 113);
  line-height: 1.5rem;
}
.input-label-container input:-ms-input-placeholder, .input-label-container textarea:-ms-input-placeholder, .input-label-container select:-ms-input-placeholder {
  font-weight: 200;
  font-size: 0.8rem;
  color: rgb(112, 112, 113);
  line-height: 1.5rem;
}
.input-label-container input::placeholder, .input-label-container textarea::placeholder, .input-label-container select::placeholder {
  font-weight: 200;
  font-size: 0.8rem;
  color: rgb(112, 112, 113);
  line-height: 1.5rem;
}
.input-label-container input:focus, .input-label-container textarea:focus, .input-label-container select:focus {
  outline: none;
  background-color: transparent;
}
.input-label-container input:focus::-moz-placeholder, .input-label-container textarea:focus::-moz-placeholder, .input-label-container select:focus::-moz-placeholder {
  color: transparent;
}
.input-label-container input:focus:-ms-input-placeholder, .input-label-container textarea:focus:-ms-input-placeholder, .input-label-container select:focus:-ms-input-placeholder {
  color: transparent;
}
.input-label-container input:focus::placeholder, .input-label-container textarea:focus::placeholder, .input-label-container select:focus::placeholder {
  color: transparent;
}
.input-label-container:after {
  content: "";
  margin: -1px auto 0;
  display: inline-block;
  height: 2px;
  width: 0;
  background-color: rgb(242, 120, 128);
}
.input-label-container:focus-within:after {
  width: 100%;
  transition: width 0.5s ease;
}
.input-label-container:focus-within label {
  color: rgb(242, 120, 128);
}/*# sourceMappingURL=registerLogin.css.map */