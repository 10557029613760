$bright-background: #F3F3F5;
$gray-background: rgb(226,225,231);
$text-gray: #5D5C61;
$text-gray2:rgb(112,112,113);
$text-black: rgb(0,0,0);
$roboto: 'Roboto', sans-serif;
$pink:#f2777f;



// burger
nav{
    max-width:1200px;
    & ul{
        margin-left: auto;
        width: 50%;
        list-style: none;
        gap: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    & li{
        &.nav-li{
            display: none;
        }
        &.nav-li.visible-on-side{
            display: block;
        }
        &:hover{
            color: $text-black;
            cursor: pointer;
        }
    }
    & li:first-child{
        margin-right: 1rem;
        position: absolute;
        top: 1rem;
        left: 1rem;
    }
    & li:last-child{
        margin-left: auto;
    }
    
    & .burger-icon, .close-icon{
        display: none;
        max-width: 0px;
    }

    & .burger-icon.visible-on-side,
    & .close-icon.visible-on-side {
        max-width: 45px;
        margin: -1rem 0 0;
        display: block;
    }

    & .new-invitations-nr{
        align-items: center;
        display: inline;
        background-color: $pink;
        padding: 0.1rem 0.25rem;
        border-radius: 50%;
        color: white;
        margin-left: 0.5rem;
    }
}

@media(min-width:800px){
    // nav-desktop smaller
    nav{
        & ul{
            width:auto;
            margin: 0;
            gap: 1.5rem;
            flex-direction: row;
            align-items: center;
        }
        & li:first-child{
            position: static;
            margin-right: 1rem;
        }
        & li:last-child{
            margin-left: auto;
        }
        & li.desktop{
            display: block;
        }
        & .burger-icon.visible-on-side,
        & .close-icon.visible-on-side{
            display: none;
        }
    }
}


@media(min-width:850px){
    // nav-desktop
    nav{
        & ul{
            gap: 1.8rem;
        }
        & li:first-child{
            margin-right: 3rem;
        }
    }
}