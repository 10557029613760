$bright-background: #F3F3F5;
$gray-background: rgb(226,225,231);
$text-gray: #5D5C61;
$text-gray2:rgb(112,112,113);
$text-black: rgb(0,0,0);
$roboto: 'Roboto', sans-serif;
$pink:rgb(242, 119, 127);
$transpPink: rgba(242, 119, 127, 0.2);
$transpYellow: rgb(252,237,220,0.2);
$yellow:rgb(252,237,220);
$pinkGradient: linear-gradient(45deg, $pink, $yellow);
$darkBoxShadow: 0px 2px 20px $text-gray;


.activity-full-page{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1150px;
    margin: 0 auto;
    padding: 0 2rem;
    & .suggest-activity {
        flex-basis: 100%;
        padding: 0.5rem;
        margin: 1rem 0;
        &.choose-group{
            background: none;
            background-color: $gray-background;
            margin-bottom: 0;
            text-align: left;
            border-radius: 10px 10px 0 0;
        }
    }

    & .choose-group-for-suggestion{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        width: 100%;
        margin-bottom: 1rem;
        & >*:nth-child(2n){
            background-color: $gray-background;
        }
        & >*:nth-child(2n+1){
            background-color: white;
        }
        & >*:last-child{
            border-radius: 0 0 10px 10px;
        }
        & ul{
            width: 100%;
            list-style:none;
            display: flex;
            gap: 0.5rem;
            padding: 0.25rem;
            &:hover{
                cursor: pointer;
                background-color: $text-black;
                & li{
                    color: white;
                }
            }
            & li{
               text-transform: capitalize;
            }
            
            
        }
    }

    & .image-galery{
        flex-basis: 100%;
        box-shadow: $darkBoxShadow;
        border-radius: 10px;
        z-index: 1;
        width: 100%;
        gap: 0.4rem;
        display: grid;
        grid-template-columns: 50% 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        max-height: 400px;
        overflow: hidden;
        & div{
            max-height: 200px;
            &:nth-child(1){
                max-height: 400px;
                grid-column: 1/2;
                grid-row:1/3;
                &:last-child{
                    grid-column: 1/4;
                    grid-row:1/3;
                }
            }
            &:nth-child(2){
                &:last-child{
                    grid-column: 2/4;
                    grid-row:1/3;
                    max-height: 100%;
                }
                &:nth-last-child(2){
                    grid-column: 2/4;
                }
            }
            &:nth-child(3){
                &:last-child{
                    grid-column: span 2;
                    grid-column-end: 4;
                    grid-row-end: 3;
                }
            }
            &:nth-child(4){
                &:last-child{
                    grid-column: span 2;
                }
            }

            & img{
                z-index: -1;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        } 
    }

    & .activity-details-and-description{
        flex-basis:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    & .description{
        margin-top: 0rem;
        padding: 1rem 0.5rem;
        text-align: justify;
        & p{
            margin-top: 0.5rem;
        }
    }
    
    .bottom-part{
        flex-basis: 100%;
        & .usefull-links{
            border-bottom: 1px solid $text-gray;
            border-top: 1px solid $text-gray;
            padding: 2rem 0;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            & .title{
                color: $pink;}

            & a{
                color: $text-black;
                &:hover{
                    color: $pink;

                }
            }
        }
        & .buttons{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            padding: 1rem 0;
        }
    }

    & .activity-details{
        float: left;
        min-width: 275px;
        max-width: 350px;
        text-align: right;
        padding: 1.5rem;
        box-shadow: $darkBoxShadow;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        border-radius: 10px;
        margin: 2rem auto;
        
        & .detail-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;
            & h2 {
                font-weight: 300;
                font-size: 1rem;
                color: $text-gray;
                line-height: 1.5rem;
            }
            & h2:after{
                content: ":";
            }
            & .type{
                text-transform: capitalize;
                margin: 0 auto 1rem;
                color: $pink;
            }
            &.price-detail{
                margin-top: 1rem;
                border-top: 1px solid $text-gray;
                padding-top: 1rem;
            }
            
        }
        & .location-icon{
            position: relative;
            min-width: 40px;
            height: 40px;
            & .top-bubble{
                background-color: $pink;
                width: 25px;
                height: 25px;
                margin: 2px auto;
                border-radius: 50%;
            }
            & .small-bubble{
                position: absolute;
                top: 9px;
                z-index: 10;
                right: 15px;
                background-color: white;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }
            & .triangle{
                background-color: $pink;
                width: 15px;
                height: 15px;
                position: absolute;
                bottom: 12px;
                right: 12.5px;
                transform: rotate(45deg);
            }
        }
    }
}

@media (min-width: 625px){
    .activity-full-page{
        & .activity-details-and-description{
            display: block;
        }
        & .activity-details{
            margin: 2rem 2rem 2rem 0;
        }
        & .description{
            margin-top: 1rem;
            padding: 1rem auto;
        }
    }
}