.groups .text-bold-uppercase, .invitations .text-bold-uppercase {
  margin-bottom: 2rem;
}
.groups .modal-content, .invitations .modal-content {
  padding: 0.5rem;
  margin: 1rem auto;
}
.groups .description, .invitations .description {
  text-align: center;
  margin: 1rem auto;
}

.invitations .btn {
  margin: 1rem auto 0.5rem;
}

.groups .one-group {
  position: relative;
}
.groups .one-group:hover {
  cursor: pointer;
  background: linear-gradient(45deg, #f2777f, rgb(252, 237, 220));
  box-shadow: 0px 2px 20px rgb(252, 237, 220);
}
.groups .one-group:hover .invited-no-resp li, .groups .one-group:hover .invited-no-resp p {
  color: white;
  transition: color 0.2s ease;
}
.groups .one-group:hover li:first-child {
  color: rgb(0, 0, 0);
  transition: color 0.2s ease;
}
.groups .one-group .invited-no-resp {
  display: flex;
  gap: 0.3rem;
  margin: -1rem 0 0 1rem;
}
.groups .one-group .invited-no-resp li, .groups .one-group .invited-no-resp p {
  text-transform: capitalize;
  list-style: none;
  color: rgb(112, 112, 113);
}
.groups .one-group .invited-no-resp li:not(:last-child)::after {
  content: ",";
}
.groups .one-group.current-group {
  background: linear-gradient(45deg, #f2777f, rgb(252, 237, 220));
  box-shadow: 0px 2px 20px rgb(252, 237, 220);
}
.groups .one-group.current-group li:first-child {
  color: rgb(0, 0, 0);
}
.groups .one-group.current-group:hover {
  cursor: default;
}
.groups .one-group.current-group .invited-no-resp li, .groups .one-group.current-group .invited-no-resp p {
  color: white;
}
.groups .group-members-list {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  list-style: none;
}
.groups .group-members-list > *:first-child {
  text-align: center;
  flex-basis: 100%;
  margin-bottom: 0.5rem;
}
.groups .group-members-list li {
  margin-right: 0.3rem;
}
.groups .group-members-list li:not(:last-child):not(:first-child)::after {
  content: ",";
}
.groups .leave-group {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.groups > *:nth-last-child(2) {
  margin-bottom: 3rem;
}
.groups .new-group, .groups .join-group {
  margin-top: 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
}
.groups .new-group .add-members, .groups .join-group .add-members {
  position: absolute;
  top: 0rem;
  left: 12rem;
}
.groups .new-group .send-invitations, .groups .join-group .send-invitations {
  margin-top: 1rem;
  min-width: 100%;
}
.groups .new-group .not-invited-display, .groups .join-group .not-invited-display {
  margin: -1rem 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}/*# sourceMappingURL=mygroups.css.map */