nav {
  max-width: 1200px;
}
nav ul {
  margin-left: auto;
  width: 50%;
  list-style: none;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
nav li.nav-li {
  display: none;
}
nav li.nav-li.visible-on-side {
  display: block;
}
nav li:hover {
  color: rgb(0, 0, 0);
  cursor: pointer;
}
nav li:first-child {
  margin-right: 1rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
}
nav li:last-child {
  margin-left: auto;
}
nav .burger-icon, nav .close-icon {
  display: none;
  max-width: 0px;
}
nav .burger-icon.visible-on-side, nav .close-icon.visible-on-side {
  max-width: 45px;
  margin: -1rem 0 0;
  display: block;
}
nav .new-invitations-nr {
  align-items: center;
  display: inline;
  background-color: #f2777f;
  padding: 0.1rem 0.25rem;
  border-radius: 50%;
  color: white;
  margin-left: 0.5rem;
}

@media (min-width: 800px) {
  nav ul {
    width: auto;
    margin: 0;
    gap: 1.5rem;
    flex-direction: row;
    align-items: center;
  }
  nav li:first-child {
    position: static;
    margin-right: 1rem;
  }
  nav li:last-child {
    margin-left: auto;
  }
  nav li.desktop {
    display: block;
  }
  nav .burger-icon.visible-on-side, nav .close-icon.visible-on-side {
    display: none;
  }
}
@media (min-width: 850px) {
  nav ul {
    gap: 1.8rem;
  }
  nav li:first-child {
    margin-right: 3rem;
  }
}/*# sourceMappingURL=nav.css.map */