
$bright-background: #F3F3F5;
$gray-background: rgb(226,225,231);
$text-gray: #5D5C61;
$text-gray2:rgb(112,112,113);
$text-black: rgb(0,0,0);
$roboto: 'Roboto', sans-serif;


.activity-card-small{
    height:100%;
    min-width: 200px;
    text-align: center;
    padding: 1rem;
    transition: all 0.2s ease;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover:not(.empty){
        background-color: white;
        box-shadow: 0 0 5px 3px black;    
        transform: scale(1.01);
    }
    
    & > a{
        min-height: 370px;
        text-decoration: none;
    }

    & img{
        border-radius: 10px;
        width: 100%;
        max-height: 285px;
        min-height: 285px;
        object-fit: cover;
        box-shadow: 0px 2px 10px black;
    }
    & .text-part{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0.5rem;
        margin-bottom: auto;;

        & h3{
            flex-basis: 100%;
        }
    }

    & .details{
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.4rem;
        padding: 1rem 0;

        & > *{
            display: flex;
            gap: 0.4rem;
            &:not(:last-child)::after{
                padding: 0;
                margin: 0;
                content: "·";
            }
        }
    }
    & .small-card-buttons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }
}

.activity-card-mini{
    max-height: 200px;
    min-height: 150px;
    text-align: center;
    border-radius: 10px;
    transition: box-shadow 0.2s ease;
    &:hover{
        cursor: pointer;
    }
    & > a{
        height: 100%;
        display:flex;
        flex-wrap: wrap;
    }
    & .image-cont{
        width: 100%;  
        min-height: 100%;
        max-height: 100%;
        position: relative;
    }
    & img{
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0 0;
        object-fit: cover;
        box-shadow: 0px 2px 10px black;
    }
    & .text-part{
        position: absolute;
        z-index: 3;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0.25rem;
        border-radius: 0 0 10px 10px;
        background-color: rgba(255, 255, 255, 0.9);
    }
    &:hover:not(.empty){
        background-color: white;
        box-shadow: 0 0 5px 3px black;    
        transform: scale(1.01);
    }
}

.grid-child.empty{
    display:none;
}

@media(min-width:500px){
    .grid-child.empty.activity-card-mini{
        display:block;
        &:hover{
            cursor:default;
        }
    
    }
}
@media(min-width:780px){
    .grid-child.empty.activity-card-small{
        display:block
    }
}